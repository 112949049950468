import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./languages/english.json";
import Spanish from "./languages/spanish.json";

const resources = {
    en: {
      translation: English
    },
    es: {
      translation: Spanish
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "es",
    debug: true,
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
