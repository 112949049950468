import React, { useEffect, useState, useRef } from "react";
import Lago from "../../assets/images/homePage/Lago.JPG";
import Reptil from "../../assets/images/homePage/Reptil.JPG";
import Ave from "../../assets/images/homePage/Ave.JPG";
const images = [Lago,Reptil, Ave]

const CarouselHomePage = () => {
  const delay = 4000;
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const totalImages = images.length;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === totalImages - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);
  return (
      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {images.map((src, index) => (
            <img
              className="slide"
              key={index}
              src= {src}
            ></img>
          ))}
        </div>
      </div>
    );
};

export default  CarouselHomePage;
