import backButton from "../../assets/images/icons/backBtn.png";
import {FaRegQuestionCircle} from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { buttons } from '../../animation';

const BackButton = ({setShowTutorial, activeAnimationMap}) => {
    
    const back = () => {
        //rollback[0]();
        //rollback.pop()();
        const spawn = {
                position: [0, 9, -10],
                rotation: {x:-45 ,y:0 , z:0},
            };
        let el = document.querySelector("#camera");
        const spawnData = spawn;
        el.object3D.position.set(...spawnData.position);
        el.setAttribute('look-controls','false');
        el.setAttribute('rotation', spawnData.rotation);
        el.setAttribute('look-controls','true');
    }

    return (
      <>
          <AnimatePresence >
            {activeAnimationMap && (
              <div >
                <motion.div className="flex flex-col cursor-pointer" onClick={() => setShowTutorial(true)}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={buttons}
                    transition= {{ delay: 2, duration: 1 }}
                >
                    <div className={`bg-[#E5ECFF] flex justify-center rounded-full w-14`} >
                      <FaRegQuestionCircle className="text-[#202945] h-14 w-14 justify-self-center p-2"/>
                    </div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
      </>
    );
  };

let rollback = []

export const fillPath = (funct) => {
    rollback.push(funct);
}

export default BackButton;