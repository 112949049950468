import introVideo from "../assets/introVideo.mp4";
import Footer from "../components/footer/footer";
import LoadingBar from "../components/loadingBar/loadingBar";
import { useEffect, useState, useRef } from "react";
import { MdLocationOn } from "react-icons/md";
import { api } from "../api/management";
import Map from "../components/map/map";
import conareLogo from "../assets/images/logos/Logo_Conare.png";
import landscapeIcon from "../assets/images/icons/turn-phone.png";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import CarouselHomePage from "../components/carousel/carouselHomepage";

const Home = ({ showLoading, MapInfo }) => {
  const { t, i18n } = useTranslation();
  let [hotspotList, setHotspotList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(1);
  const [showLoadingProgress, setShowLoadingProgress] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showModalLandscape, setShowModalLandscape] = useState(false);
  const [activeAnimation, setActiveAnimation] = useCycle(false, true);
  const [animationMsg, setAnimationMsg] = useCycle(false, true);
  const [activeAnimationMap, setActiveAnimationMap] = useCycle(false, true);

  const slogans = [
    { slogan: "TERRITORIO NORTE-NORTE", delay: 0 },
    { slogan: "UN TESORO ESCONDIDO POR DESCUBRIR", delay: 3.7 },
    { slogan: "", delay: 7.4 },
  ];

  const viewMap = () => {
    setActiveAnimation();
    setHotspots().then((hotspotsDetail) => {
      setHotspotList(hotspotsDetail);
      setShowMap(true);
    });

    setShowLoadingProgress(true);
  };

  useEffect(() => {
    if (activeAnimation) {
      setTimeout(() => {
        setAnimationMsg();
      }, 3000);
    }
  }, [activeAnimation]);

  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| ||a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const hotspots = JSON.parse(localStorage.getItem("mapInfo")).hotspots;

  const setHotspots = async () => {
    const hotspotsDetail = [];
    let stepsForLoad = 0;
    let porcentage = 0;
    for (let hotspot of hotspots) {
      hotspotsDetail.push(await api.getHotspot(hotspot.id));
      stepsForLoad = stepsForLoad + 1;

      if (porcentage < 100) {
        porcentage = stepsForLoad * 10;
        setUploadProgress(porcentage);
      }
    }
    return hotspotsDetail;
  };

  useEffect(() => {
    ReactGA.pageview("/homepage");
  }, []);

  useEffect(() => {
    let isMobile = mobileCheck();
    if (!isMobile) {
      setShowModalLandscape(true);
    }
  }, []);

  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 1.5;
    videoRef.current.playsInline = true;
    videoRef.current.setAttribute("webkit-playsinline", true);
  };

  return (
    <>
      <AnimatePresence>
        {activeAnimation && (
          <motion.video
            webkit-playsinline={"true"}
            playsInline={true}
            autoPlay={true}
            muted
            className="z-40 fixed object-cover h-screen w-screen"
            ref={videoRef}
            onCanPlay={() => setPlayBack()}
            onEnded={() => {
              setActiveAnimation();
              setAnimationMsg();
              setActiveAnimationMap();
            }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                ease: "easeOut",
                duration: 1,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                ease: "easeIn",
                duration: 1,
                delay: 0,
              },
            }}
          >
            <source type="video/mp4" src={introVideo} />
          </motion.video>
        )}
      </AnimatePresence>

      {slogans &&
        slogans.map((item, i) => (
          <AnimatePresence key={i}>
            {animationMsg && (
              <motion.div
                className="z-50 fixed h-full w-full flex items-center justify-center bg-transparent"
                key={i}
                animate={{
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 3.7,
                  ease: "easeOut",
                  delay: item.delay,
                }}
              >
                <span className="text-white text-4xl font-semibold font-mono text-center">
                  {item.slogan}
                </span>
              </motion.div>
            )}
          </AnimatePresence>
        ))}
      {showMap ? (
        <Map
          news={MapInfo.news}
          hotspotList={hotspotList}
          activeAnimationMap={activeAnimationMap}
        ></Map>
      ) : (
        <div className="max-w-full max-h-screen mx-auto bg-white h-screen lg:overflow-y-hidden">
          <div className="lg:flex h-full">
            <div className="flex flex-col lg:flex-row h-full">
              <div className="lg:w-3/4">
                <CarouselHomePage></CarouselHomePage>
              </div>
              <div className="lg:p-8 flex flex-col lg:h-full lg:px-6 lg:py-6 px-6 py-4 lg:mt-12 lg:mx-14 lg:w-2/4 h-full">
                <div className="tracking-wide text-base text-[#202945] flex items-center justify-center lg:justify-start">
                  <MdLocationOn className="mr-1" />
                  {MapInfo.locate}
                </div>
                <h1 className="lg:mt-4 flex justify-center lg:justify-start m-1 text-3xl leading-tight font-bold text-[#202945] mt-2">
                  {MapInfo.name}
                </h1>
                <p className="lg:mt-4 mt-2 text-[#202945] text-sm text-center lg:text-left text-justify m-2 lg:m-0">
                  {MapInfo.description}
                </p>
                <div className="flex flex-col lg:h-full lg:my-auto lg:justify-between h-full">
                  <div className="col-start-1 flex justify-center lg:justify-start order-2 lg:order-1 mt-[2rem] mb-[2rem] lg:mb-[-2rem]">
                    <button
                      type="button"
                      className="buttonShadow flex items-center justify-center bg-[#02F1A0] text-[#202945] text-sm leading-6 font-bold py-2 px-14 rounded-full"
                      onClick={viewMap}
                    >
                      {t("home.view-map")}
                    </button>
                  </div>
                  <div className="lg:relative lg:mt-20 lg:order-1">
                    <div className=" text-center sm:mx-auto">
                      <span className="block sm:text-center mt-4 text-[#202945]">
                        {t("home.sponsored-by")}:
                      </span>
                    </div>
                    <div className="pt-3">
                      <div className="flex justify-center">
                        <img
                          className=" lg:flex w-20 lg:h-28 lg:w-28 lg:ml-[2rem]"
                          src={conareLogo}
                          alt="Conare"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="order-3 items-end ">
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {!showModalLandscape && (
        <div className="portrait:hidden items-center flex flex-col justify-center bg-[#202945]/70 fixed top-0 left-0 lg:right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-hidden inset-0 h-full shadow-lg">
          <p className={"text-white"}>{t("home.turn-phone")}</p>
          <img className="w-14 h-14" src={landscapeIcon} alt="logo UNED" />
        </div>
      )} */}
    </>
  );
};

export default Home;
