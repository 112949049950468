import { useTranslation, withTranslation, Trans } from "react-i18next";
import poweredBy from "../../assets/images/logos/poweredby.png";

const Footer = () => {
  const { t, i18n } = useTranslation();

    return (
      <>
          <footer className="text-center text-black">
            <div className="text-center border-gray-200 sm:mx-auto lg:my-8">
              <img className="block mx-auto w-32" src={poweredBy} alt=""/>
            </div>
          </footer>
      </>
    );
  };
  
  export default Footer;