/* global AFRAME THREE */

if (typeof AFRAME === 'undefined') {
    throw new Error('Component attempted to register before AFRAME was available.');
}
AFRAME.registerComponent('camera-movement', {
    schema: {
        speed: {type: "number", default: 1}
    },
    init: function () {
        this.camera = document.querySelector("#camera");
        this.cameraPos = new THREE.Vector3(...this.camera.object3D.position);

    },
    tick: function () {
        if (this.cameraPos.x !== this.camera.object3D.position.x) {
            const vecSub = new THREE.Vector3().subVectors(this.camera.object3D.position, this.cameraPos)
            this.el.object3D.position.add(vecSub);
            this.cameraPos = new THREE.Vector3(...this.camera.object3D.position);
        }

    }

});

console.info("camera-movement component loaded to aframe")