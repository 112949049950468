import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import Home from "./pages/home";
import React, { useEffect, useState } from 'react';
import { api } from "./api/management";
import '././aframeInteract/clickHotspot';
import '././aframeInteract/alphaTest';
import '././aframeInteract/cameraMovement';
import '././aframeInteract/loadMap';
import '././aframeInteract/hoverHotspot';
import '././aframeInteract/limitCamera';
import { useTranslation } from "react-i18next";

import ReactGA from 'react-ga';

const TRACKING_ID = "UA-255356755-1"; 
ReactGA.initialize(TRACKING_ID, {
  gaOptions: {
    debug_mode: true,
  },
  gtagOptions: {
    debug_mode: true,
  },
});

function App() {
  const { t, i18n } = useTranslation();
  let [MapModel, setMapModel] = useState();

  useEffect(() => {
    let userLocale = localStorage.getItem("locale");
    if(!userLocale){
      userLocale =
      navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
    }
    userLocale = userLocale.split("-")[0] || "es";
    i18n.changeLanguage(userLocale);
    window.localStorage.setItem("locale", userLocale)
    
    window.localStorage.removeItem("chatBot");
    window.localStorage.removeItem("hotspotId");
  }, []);


  useEffect(() => {
    if(MapModel) return;
        api.getMap().then(response => {
          setMapModel(response);
          window.localStorage.setItem("mapInfo", JSON.stringify(response) )
        }
    );
}, [MapModel]);

  if(!MapModel){
    return (<div></div>);
  }
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home MapInfo={MapModel.map}/>}/>
      </Routes>
    </Router>
  );
}

export default App;
