import left from "../../assets/images/icons/left.png";
import right from "../../assets/images/icons/right.png";
import { useTranslation } from "react-i18next";
import React, { createRef,useEffect, useState } from "react";
import { Ring } from "react-aframe-ar/dist/primitives";

const CardCarousel = ({ media }) => {
  const [currentNew, setCurrentNew] = useState(0);

  if(!media.length)
    media = []

  const refs = media.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToNew = i => {
    setCurrentNew(i);
    refs[i].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const totalNews = media.length;

  const nextNew = () => {
    if (currentNew >= totalNews - 1) {
      scrollToNew(0);
    } else {
      scrollToNew(currentNew + 1);
    }
  };

const previousNew = () => {
    if (currentNew === 0) {
      scrollToNew(totalNews - 1);
    } else {
      scrollToNew(currentNew - 1);
    }
  };

  const sliderControl = (isLeft, i) => (
    
    <button
      type="button"
      onClick={isLeft ? previousNew : nextNew}
      className={`${currentNew  === i ? 'bg-[#02F1A0]' : 'bg-[#45474C]'} w-3 h-3 rounded-full`}
      style={{"margin-top": "0.7rem" }}
    > 
    </button>
  );

  function pushSliderControl(){
    const sliders= [];
    for (let i = 0; i<totalNews; i++){
      if(i==0){
        sliders.push(sliderControl(true, i))
      }
      else{
        sliders.push(sliderControl(false, i))
      }
    }
    return (
      <div className="space-x-1 flex ">
        {sliders}
      </div>
    )
  }

  const { t } = useTranslation();

    return (
      <>
        <div className="max-w-sm bg-white md:h-[21rem] md:w-full md:max-w-none md:m-2 h-14">
          <div className="carouselComponent w-full overflow-y-visible">
              <div className={'absolute z-30 flex -translate-x-1/2 bottom-px left-1/2 w-full pb-2'}>
                  <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-[2rem!important] mr-auto inline-flex items-center"
                            onClick={() => previousNew()}>
                      <img className="text-gray-800 h-6" src={ left } alt="Icon News"/>
                  </button>
                  {pushSliderControl()}
                  <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mr-[2rem!important] ml-auto inline-flex items-center"
                            onClick={() => nextNew()}>
                      <img className="text-gray-800 h-6" src={ right } alt="Icon News"/>
                  </button>
                
              </div>
            {media.map((newInfo, i) => (
              <div className="w-full flex-shrink-0" key={newInfo.number} ref={refs[i]}>
                
                <a href="#">
                  <h4 className="text-lg font-medium tracking-tight text-gray-900"> <span className="italic">{t("news.improvement")} </span> {newInfo.number} </h4>
                  <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900">{newInfo.title}</h5>
                </a>
                <div className="pt-1.5 ">
                    <img className="md:w-60 w-full object-contain" src={newInfo.image} alt="" />
                    <p className="my-3 font-normal text-gray-700 md:text-justify md:mr-6">
                    {newInfo.text}
                    </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };
  
  export default CardCarousel;