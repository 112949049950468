// eslint-disable-next-line no-undef
/* global AFRAME THREE */

if (typeof AFRAME === 'undefined') {
    throw new Error('Component attempted to register before AFRAME was available.');
}
AFRAME.registerComponent('click-hotspot', {
    schema: {
        hotspot: {type: "string", default: "{}"}
    },
    init: function () {
        const self = this;
        const el = this.el;
        setTimeout(function () {
            el.addEventListener('click', function (e) {
                e.stopPropagation();
                const hotspotClickedEvent = new CustomEvent("hotspot-clicked", {
                    detail: {
                        el: self
                    }
                });
                document.body.dispatchEvent(hotspotClickedEvent);
            });

        }, 2000)
    }
});

console.info("click-hotspot component loaded to aframe")
