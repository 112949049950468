import React, {useEffect} from 'react';
import arBtn from "../../assets/images/icons/ARIcon.svg";
import { useTranslation } from "react-i18next";
import treeIcon from "../../assets/images/icons/tree.png";
import hotelIcon from "../../assets/images/icons/hotel.png";

import MapModel from "../../assets/models/Map.glb"
const MapAR = ({hotspots, show}) => {
    const { t } = useTranslation();
    let point;

    useEffect(() => {
        document.getElementById("root").style.height = "100%";
        return () => document.getElementById("root").style.height = "initial";
    }, [])
    const hotspotIconMapping = {
        nature: treeIcon,
        hotel: hotelIcon,
    }

    const getHotspotIcon = (hotspot) => {
        return hotspot.hotspot?.type ? hotspotIconMapping[hotspot.hotspot.type] : hotspotIconMapping["nature"];
    }

    const toModelViewerPosition = (origPosition) => {
        const factor = 90;
        return `${origPosition[0]/factor+0.15} 0.1 ${origPosition[2]/factor-.1}`;
    }

    const hotspotColorMapping={
        nature: "#228B22",
        cultural: "#90EE90",
        political: "#0000FF",
        tourist: "#BEBEBE",
      }

    const changeColor=(hotspot)=>{
        point=document.getElementById(`circle-${hotspot.hotspot.name}`);
        if(point!=null){
            point.style.backgroundColor= hotspotColorMapping[hotspot.hotspot.type];
        }
        
    }

    return (  
        <div className={show ? "" : "hidden"}>

            <model-viewer alt={"3D map model"} src={MapModel} ar class={"map-model-viewer"}>


                            <button slot="ar-button" className={`bg-[#E5ECFF] flex justify-center rounded-full w-14`} id="ar">
                                <img className={`h-14 w-14 justify-self-center p-2`} src={arBtn}/>
                            </button>

                        {hotspots.map((hotspot) => <button key={hotspot.hotspot.name}
                                                           className={"map-model-viewer-annotation"} name={hotspot.hotspot.name}
                                                           slot={"hotspot-"+hotspot.hotspot.id}
                                                           data-position={toModelViewerPosition(hotspot.hotspot.position)}>
                            <div className="annotation">{hotspot.hotspot.name}
                                <div id={`circle-${hotspot.hotspot.name}`} className='circle'/>
                                {changeColor(hotspot)}
                            </div>
                        </button>)}

            </model-viewer>
        </div>
    );
  };
  
  export default MapAR;