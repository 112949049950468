import closeIcon from "../../assets/images/icons/closeIcon.png";
import Card from "../card/card";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion, useCycle } from "framer-motion";

const News = ({ news, setShowNewsModal, setModalOpen }) => {
  const { t } = useTranslation();
  const [animation, setAnimation] = useCycle(true, false);

  const onClick = () => {
    setAnimation();
    setTimeout(() => {
      setShowNewsModal(false);
      setModalOpen(false);
    }, 150);
  };

  return (
    <>
      <AnimatePresence>
        {animation && (
          <>
            <div className="fixed inset-0 bg-[#202945] opacity-75 z-50"></div>
            <motion.div
              className="lg:flex lg:justify-center lg:items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden lg:inset-0 h-full lg:h-full shadow-lg"
              initial={{ opacity: 0, scale: 0.75 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ase: "easeIn",
                  duration: 0.15,
                },
              }}
            >
              <div className="bg-white lg:justify-center relative w-full h-4/6 shadow-lg lg:shadow-none lg:w-1/2 lg:flex lg:h-[33rem] overflow-y-auto">
                <div className="relative bg-white h-full px-4 py-4">
                  <div className="flex items-start justify-between lg:p-4 lg:pt-4 ">
                    <h3 className="text-xl font-bold text-gray-900 lg:m-4">
                      {t("navbar.news")}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                      onClick={onClick}
                    >
                      <img
                        className="text-gray-800 h-6"
                        src={closeIcon}
                        alt="Icon News"
                      />
                    </button>
                  </div>
                  <div className="space-y-6 lg:h-1/5">
                    <Card media={news} />
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default News;
