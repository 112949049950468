import closeIcon from "../../assets/images/icons/closeIcon.png";
import tut1 from "../../assets/images/tutorial/tutorial1.gif";
import tut2 from "../../assets/images/tutorial/tutorial2.gif";
import tut3 from "../../assets/images/tutorial/tutorial3.gif";
import tut4 from "../../assets/images/tutorial/tutorial4.gif";
import tut5 from "../../assets/images/tutorial/tutorial5.gif";
import tut6 from "../../assets/images/tutorial/tutorial6.gif";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AnimatePresence, motion, useCycle } from "framer-motion";

const Tutorial = ({ setShowTutorial }) => {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [animation, setAnimation] = useCycle(true, false);
  const [overlay, setOverlay] = useState(true);

  const [tutorial, setTutorial] = useState([
    {
      name: t("tutorial.name1"),
      description: t("tutorial.description1"),
      src: tut1,
    },
    {
      name: t("tutorial.name2"),
      description: t("tutorial.description2"),
      src: tut2,
    },
    {
      name: t("tutorial.name3"),
      description: t("tutorial.description3"),
      src: tut4,
    },
    {
      name: t("tutorial.name4"),
      description: t("tutorial.description4"),
      src: tut3,
    },
    {
      name: t("tutorial.name5"),
      description: t("tutorial.description5"),
      src: tut5,
    },
    {
      name: t("tutorial.name6"),
      description: t("tutorial.description6"),
      src: tut6,
    },
  ]);

  const hideTutorial = () => {
    setAnimation();
    setOverlay(false);
    setTimeout(() => {
      setShowTutorial(false);
      window.localStorage.setItem("showTutorial", false);
    }, 150);
  };

  const next = () => {
    if (index === 5) {
      setShowTutorial(false);
      return;
    }

    if (index === tutorial.length - 1) {
      setIndex(0);
      return;
    }
    setIndex(index + 1);
  };

  const previous = () => {
    if (index === 0) {
      setIndex(0);
      return;
    }
    setIndex(index - 1);
  };

  return (
    <>
      <AnimatePresence>
        {animation && (
          <>
            <div className="absolute inset-0 bg-[#202945] opacity-75 z-20"></div>
            <motion.div
              className={`flex justify-center h-auto md:w-1/2 bg-white md:text-sm fixed bottom-28 md:bottom-0 inset-x-0 z-30 m-auto md:mx-auto p-4 rounded mx-2`}
              initial={{ opacity: 0, scale: 0.75 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ease: "easeIn",
                  duration: 0.15,
                },
              }}
            >
              <div className="relative w-full md:h-auto ">
                <div className="relative h-full">
                  <div className="space-y-6 bg-white rounded md:rounded-md h-full">
                    <div className="max-w-sm md:max-w-full h-full">
                      <div className={`flex flex-col h-full`}>
                        <div className="md:mt-4 md:mb-8 my-2">
                          <div className="flex items-start justify-center">
                            <h3 className={`text-[#202945] text-2xl font-bold`}>
                              Tutorial
                            </h3>
                            <button
                              type="button"
                              className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                              onClick={() => hideTutorial()}
                            >
                              <img
                                className="text-gray-800 h-6"
                                src={closeIcon}
                                alt="Icon News"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-4">
                          <div className="relative w-full order-3 md:order-1">
                            <img
                              className="w-full h-auto"
                              src={tutorial[index].src}
                              alt="Tutorial gif"
                              width="250"
                            />
                          </div>
                          <div className="md:my-2 w-full md:w-1/2 text-justify md:m-auto order-1">
                            <div className="order-1 md:order-2 mb-2">
                              <p className="whitespace-pre-wrap font-normal text-[#202945] md:mb-4 italic">
                                {t("tutorial.step")} {index + 1} / 6
                              </p>
                            </div>
                            <div className="order-2 md:order-3 mb-2">
                              <p className="whitespace-pre-wrap text-[#202945] md:mb-4 font-bold text-lg">
                                {tutorial[index].name}
                              </p>
                            </div>
                            <div className="order-4 h-32 md:h-52 overflow-y-auto scrollbar">
                              <p className="whitespace-pre-wrap font-normal text-[#202945] md:mb-4 mb-2">
                                {tutorial[index].description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row justify-center mt-auto items-end md:space-x-4">
                          <div className="w-full md:w-1/2 mt-auto">
                            <div className="flex justify-center md:justify-end">
                              <button
                                type="button"
                                disabled={index === 0 ? true : false}
                                className={`disabled:opacity-50 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500 md:w-60 bg-white text-[#02F1A0] border-2 border-[#02F1A0] w-3/4 flex items-center justify-center  text-sm leading-6 font-bold py-1 rounded-full`}
                                onClick={() => previous()}
                              >
                                {t("tutorial.previous")}
                              </button>
                            </div>
                          </div>
                          <div className="w-full md:w-1/2 mt-auto">
                            <div className="flex justify-center md:justify-start">
                              <button
                                type="button"
                                className="md:w-60 border-2 border-[#02F1A0] bg-[#02F1A0] text-[#202945] w-3/4 flex items-center justify-center  text-sm leading-6 font-bold py-1 rounded-full"
                                onClick={() => next()}
                              >
                                {index + 1 === 6
                                  ? t("tutorial.close")
                                  : t("tutorial.continue")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Tutorial;
