import upalaImage from "../../assets/images/upala.jpg";
import guatusoImage from "../../assets/images/Guatuso.jpg";
import losChilesImage from "../../assets/images/loschiles.jpeg";
import closeIcon from "../../assets/images/icons/closeIcon.png";
import { useEffect, useState } from "react";
import escudo from "../../assets/images/logos/escudo.png";
import escudoUpala from "../../assets/images/logos/EscudoUpala.png";
import losChiles from "../../assets/images/logos/losChiles.png";
import muniGuatuso from "../../assets/images/logos/MuniGuatuso.png";
import upala from "../../assets/images/logos/upala.png";
import { FiPhone, FiMail, FiFacebook } from "react-icons/fi";
import { MdPersonOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion, useCycle } from "framer-motion";

const LocationModal = ({
  name,
  subtitle,
  text,
  setShowLocationModal,
  setLocationSelected,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [animation, setAnimation] = useCycle(true, false);

  const cantonImageMapping = {
    "Los Chiles": losChilesImage,
    Guatuso: guatusoImage,
    Upala: upalaImage, // TODO: not oficial Upala tour
  };

  const virtualToursMapping = {
    "Los Chiles": "https://tours.rutaturisticanortenorte.go.cr/norte-loschiles",
    Guatuso: "https://tours.rutaturisticanortenorte.go.cr/norte-guatuso",
    Upala: "https://tours.rutaturisticanortenorte.go.cr/norte-upala",
  };

  const losChilesInfo = (
    <div className="my-4 mt-6 w-full lg:w-1/2">
      <h3 className="text-xl mb-2 font-bold text-[#202945] lg:text-lg">
        {t("location.muni-contact")}
      </h3>
      <div className="container py-4">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#06475D] italic lg:text-lg">
            {t("location.municipality")}
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiPhone className="text-[#202945]" />
            </div>
            <p className="font-normal flex text-[#06475D]">
              (506) 2471-1036 / 2471-1038
            </p>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#06475D] italic lg:text-lg">
            Ruta Norte Costa Rica
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/northernroutecr"
            >
              Ruta Norte CR
            </a>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#06475D] italic lg:text-lg">
            Cámara de Turismo y Comercio de Los Chiles - CATUCHI
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/catuchiFN"
            >
              CATUCHI
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  const guatusoInfo = (
    <div className="my-4 mt-6 w-full lg:w-1/2">
      <h3 className="text-xl mb-2 font-bold text-[#202945] lg:text-lg">
        {t("location.muni-contact")}
      </h3>
      <div className="container py-4">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#25593B] italic lg:text-lg">
            {t("location.municipality")}
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiPhone className="text-[#202945]" />
            </div>
            <p className="font-normal flex text-[#06475D]">(506) 2464 0065</p>
          </div>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiMail className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              href="mailto:alcaldia@muniguatuso.go.cr?"
            >
              alcaldia@muniguatuso.go.cr
            </a>
          </div>
        </div>
      </div>
      <div className="container py-4">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#25593B] italic lg:text-lg">
            Ruta Norte Costa Rica
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/northernroutecr"
            >
              Ruta Norte CR
            </a>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#25593B] italic lg:text-lg">
            Cámara Turismo y Guías de Guatuso y Tenorio
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/people/C%C3%A1mara-Turismo-y-Guias-de-Guatuso-y-Tenorio/100065260403761/ "
            >
              Cámara de Turismo
            </a>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
  const upalaInfo = (
    <div className="my-4 mt-6 w-full lg:w-1/2">
      <h3 className="text-xl mb-2 font-bold text-[#202945] lg:text-lg">
        {t("location.muni-contact")}
      </h3>
      <div className="container py-2 pt-4">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#61A830] italic lg:text-lg">
            {t("location.municipality")}
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiPhone className="text-[#202945]" />
            </div>
            <p className="font-normal flex text-[#06475D]">(506) 2470-0157</p>
          </div>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiMail className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              href="mailto:info@muniupala.go.cr?"
            >
              info@muniupala.go.cr
            </a>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#61A830] italic lg:text-lg">
            Ruta Norte Costa Rica
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/northernroutecr"
            >
              Ruta Norte CR{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#61A830] italic lg:text-lg">
            Cámara de Turismo del Rincón de la Vieja - Caturvi
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/caturvicr"
            >
              CATURVI CR
            </a>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#61A830] italic lg:text-lg">
            Cámara de Turismo de Rio Celeste - Caturi
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/CATURICR"
            >
              CATURI CR
            </a>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#61A830] italic lg:text-lg">
            Cámara De Turismo Río Zapote - Catupa
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/camaradeturismoupala"
            >
              CATUPA
            </a>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="flex flex-col justify-center">
          <h3 className="text-lg mb-2 font-bold text-[#61A830] italic lg:text-lg">
            Cámara de Turismo Tenorio Miravalles - Cattemi
          </h3>
          <div className="flex items-center mb-2">
            <div className="bg-[#E5ECFF] rounded-full p-1 mr-4">
              <FiFacebook className="text-[#202945]" />
            </div>
            <a
              className="font-normal flex text-[#06475D] underline"
              target="_blank"
              href="https://www.facebook.com/CATTEMI"
            >
              CATTEMI
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| ||a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  useEffect(() => {
    let isMobile = mobileCheck();
    if (!isMobile) {
      setShowLocationDetails(true);
    }
  }, []);

  const onClick = () => {
    setAnimation();

    setTimeout(() => {
      setModalOpen(false);
      setShowLocationModal(false);
      setLocationSelected("");
    }, 150);
  };

  return (
    <>
      <AnimatePresence>
        {animation && (
          <>
            <div className="fixed inset-0 bg-[#202945] opacity-75 z-10"></div>
            <motion.div
              className={`${
                showLocationDetails ? "h-3/4" : ""
              } lg:w-3/5 bg-white lg:text-sm fixed z-50 p-4 scrollbar overflow-y-scroll rounded mx-2 w-11/12`}
              style={{ height: "90vh !important", bottom: "5vh" }}
              initial={{ opacity: 0, scale: 0.75 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ase: "easeIn",
                  duration: 0.15,
                },
              }}
            >
              <button
                type="button"
                className="sticky top-0 z-40 left-[95%] cursor-pointer text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                onClick={onClick}
              >
                <img
                  className="text-gray-800 h-6"
                  src={closeIcon}
                  alt="Icon News"
                />
              </button>
              <div className="relative w-full ">
                <div className="relative">
                  <div className>
                    <div className="flex items-start justify-center">
                      <h3
                        className={`${
                          name === "Los Chiles"
                            ? "text-[#06475D]"
                            : name === "Guatuso"
                            ? "text-[#25593B]"
                            : "text-[#61A830]"
                        } text-2xl font-bold`}
                      >
                        {t("location.canton")} {name}
                      </h3>
                    </div>
                    <h3 className="text-sm text-gray-400 mb-2 italic">
                      {subtitle}
                    </h3>
                  </div>
                  <div className="space-y-6 bg-white rounded lg:rounded-md h-full">
                    <div className="max-w-full">
                      <div
                        className={`${!showLocationDetails ? "hidden" : ""}`}
                      >
                        <div className="flex flex-col lg:flex-row">
                          <div className="relative lg:w-1/2 lg:mr-8">
                            <img
                              className="h-52 lg:h-60 w-full rounded-t object-cover"
                              src={cantonImageMapping[name]}
                              alt=""
                            />
                          </div>
                          <div className="my-2 w-full lg:w-1/2 text-justify lg:m-auto">
                            <p className="whitespace-pre-wrap font-normal text-[#202945] lg:mb-4 ">
                              {text}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col lg:flex-row">
                          {name === "Los Chiles"
                            ? losChilesInfo
                            : name === "Guatuso"
                            ? guatusoInfo
                            : upalaInfo}
                          <div className="my-6 w-full lg:w-1/2">
                            <h3
                              className={`${
                                name === "Los Chiles"
                                  ? "text-[#06475D]"
                                  : name === "Guatuso"
                                  ? "text-[#25593B]"
                                  : "text-[#61A830]"
                              } text-xl font-bold mb-2 lg:text-lg flex justify-center`}
                            >
                              {t("location.sponsors")}
                            </h3>
                            <div className="container pt-3 flex justify-center">
                              <div
                                className={`${
                                  name === "Los Chiles"
                                    ? "flex mb-9 justify-center"
                                    : "hidden"
                                } `}
                              >
                                <img
                                  className="mx-4 text-gray-800 h-1/6"
                                  src={losChiles}
                                  alt="logo UNED"
                                />
                              </div>
                              <div
                                className={`${
                                  name === "Guatuso" ? "flex mb-9" : "hidden"
                                } `}
                              >
                                <img
                                  className="mx-4 text-gray-800 w-1/3 ml-auto mr-auto"
                                  src={muniGuatuso}
                                  alt="Escudo"
                                />
                              </div>
                              <div
                                className={`${
                                  name === "Upala" ? "flex mb-9" : "hidden"
                                } `}
                              >
                                <img
                                  className="mx-4 text-gray-800"
                                  src={upala}
                                  alt="Escudo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" border-t-2 -mx-4">
                        <div className="mx-8 py-2">
                          <div className="col-start-1 flex justify-center lg:justify-start">
                            <button
                              type="button"
                              className={`${
                                name === "Los Chiles"
                                  ? "text-[#EE2E32] border border-[#EE2E32]"
                                  : name === "Guatuso"
                                  ? "text-[#25593B] border border-[#25593B]"
                                  : "text-[#014F59] border border-[#014F59]"
                              } 
                                                        ${
                                                          showLocationDetails ||
                                                          !mobileCheck
                                                            ? "hidden"
                                                            : "w-3/4 flex items-center justify-center text-sm leading-6 font-bold py-1 rounded-full"
                                                        }`}
                              onClick={() => setShowLocationDetails(true)}
                            >
                              {t("location.see-more-about-canton")}
                            </button>
                          </div>
                        </div>
                        <div className="mx-8">
                          <div className="col-start-1 flex justify-center">
                            <a
                              type="button"
                              href={virtualToursMapping[name]}
                              target="_blank"
                              className={`lg:w-60 ${
                                name === "Los Chiles"
                                  ? "bg-[#EE2E32] text-white"
                                  : name === "Guatuso"
                                  ? "bg-[#EDCE79] text-[#25593B]"
                                  : "bg-[#014F59] text-white"
                              } 
                                                            w-3/4 flex items-center justify-center  text-sm leading-6 font-bold py-1 rounded-full`}
                            >
                              {t("location.go-to-tour")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default LocationModal;
