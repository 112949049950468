import React, { useCallback, useEffect, useState, useRef } from "react";
import Background from "../../assets/images/fondo.png";
import LocationNavbar from "../locationNavbar/locationNavbar";
import Navbar from "../navbar/navbar";
import Hotspot from "../hotspot/hotspot";
import HotspotDetail from "../hotspotDetail/hotspotDetail";
import BackButton from "../backButton/backButton";
import ArButton from "../arButton/arButton";
import MapAR from "../arMap/arMap";
import Tutorial from "../tutorial/tutorial";
import Clouds from "../clouds/clouds";
import ColorsSymbology from "../colorsSymbology/colorsSymbology";

let makingQuery = false;

let globalZoom = 1;
let initialTouchDistance = 0;
const zoomLimits = { max: 3.5, min: 0.5 };
const mobileZoomLimits = { max: 3.5, min: -0.5 };

const Map = ({ news, hotspotList, activeAnimationMap }) => {
  const [showHotspotDetail, setShowHotspotDetail] = useState(false);
  const [showArMap, setshowArMap] = useState(false);
  const [hotspotDetail, setHotspotDetail] = useState({});
  const [hotspotMultimedia, sethotspotMultimedia] = useState([]);
  const [showChatNav, setShowChatNav] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [applyOverlay, setApplyOverlay] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [symbologyOpen, setSymbologyOpen] = useState(false);
  const timeoutRef = useRef(null);
  const [posCamera, setPosCamera] = useState(" ");
  const [rotationCamera, setRotationCamera] = useState(" ");

  useEffect(() => {
    setModalOpen(showArMap || showTutorial || showHotspotDetail);
  }, [showArMap, showTutorial, showHotspotDetail]);

  const onWheel = useCallback(
    (e) => {
      if (e.deltaY === 0) return;

      const multiplier = e.deltaY < 0 ? 1 : -1;
      let newZoom = zoom + 0.01 * multiplier;
      // console.log(
      //   newZoom,
      //   zoomLimits,
      //   showArMap,
      //   showTutorial,
      //   showHotspotDetail
      // );
      if (newZoom >= zoomLimits.max || newZoom <= zoomLimits.min) return;
      if (modalOpen) return;
      setZoom(newZoom);
    },
    [setZoom, zoom, modalOpen]
  );

  const onPinchStart = useCallback((e) => {
    if (modalOpen) return;

    if (e.touches.length === 2) {
      initialTouchDistance = Math.hypot(
        e.touches[0].pageX - e.touches[1].pageX,
        e.touches[0].pageY - e.touches[1].pageY
      );
    }
  }, []);

  const onPinchEnd = useCallback((e) => {
    initialTouchDistance = 0;
  }, []);

  const onPinch = useCallback((e) => {
    let zoomSpeed = 0.1;
    if (modalOpen) return;
    if (e.touches.length === 2) {
      let dist = Math.hypot(
        e.touches[0].pageX - e.touches[1].pageX,
        e.touches[0].pageY - e.touches[1].pageY
      );

      const multiplier = dist > initialTouchDistance ? 1 : -1;
      if (dist === initialTouchDistance) return;
      let newZoom = globalZoom + zoomSpeed * multiplier;

      if (newZoom >= mobileZoomLimits.max || newZoom <= mobileZoomLimits.min)
        return;

      globalZoom += 0.01 * multiplier;
      setZoom(globalZoom + 1);
      initialTouchDistance = dist;
    }
  }, []);

  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  useEffect(() => {
    setTimeout(function () {
      const canvas = document.querySelector(".a-canvas");

      canvas.addEventListener("touchmove", onPinch);
      canvas.addEventListener("touchstart", onPinchStart);
      canvas.addEventListener("touchend", onPinchEnd);
    }, 100);

    setZoom(0.6);
    setTimeout(function () {
      if (window.localStorage.getItem("showTutorial") === "false") {
        setShowTutorial(false);
      } else {
        setShowTutorial(true);
      }
    }, 23000);
  }, []);

  const callback = useCallback(
    (name) => {
      if (name !== "Chat") return;
      const isMobile = mobileCheck();
      if (isMobile) {
        setShowChatNav(true);
      }
    },
    [setModalOpen, setShowChatNav]
  );

  useEffect(() => {
    const clickedListener = (data) => {
      let hotspotId = data.detail.el.el.id;
      window.localStorage.setItem("hotspotId", hotspotId);
      sethotspotMultimedia(JSON.parse(data.detail.el.data.hotspot).multimedia);
      setHotspotDetail(JSON.parse(data.detail.el.data.hotspot).hotspot);

      if (!showHotspotDetail) setShowHotspotDetail(true);
    };
    const body = document.body;
    if (body.getAttribute("has-hotspot-clicked-listener") !== "true") {
      body.setAttribute("has-hotspot-clicked-listener", "true");
      body.addEventListener("hotspot-clicked", clickedListener);
    }

    return () =>
      document.removeEventListener("hotspot-clicked", clickedListener);
  }, []);

  useEffect(() => {
    if (makingQuery) return;
    makingQuery = true;

    const isMobile = mobileCheck();
    if (isMobile) {
      setPosCamera("-7 15 10");
      setRotationCamera("-14 0 0");
    } else {
      setPosCamera("-12 -10 12");
      setRotationCamera("7 0 0");
    }
  }, []);

  useEffect(() => {
    if (hotspotMultimedia.length && hotspotDetail.name) {
      setShowHotspotDetail(true);
    }
  }, [hotspotMultimedia]);

  const getHotspots = () => {
    const list = [];
    for (let hotspot of hotspotList) {
      list.push(
        <Hotspot
          key={hotspot.hotspot.id}
          hotspot={hotspot.hotspot}
          hotspotInfo={hotspot}
        />
      );
    }
    return list;
  };
  return (
    <div onWheel={onWheel}>
      <div className={`${showChatNav ? "hidden" : "z-10 w-full fixed"}`}>
        <Navbar news={news} setModalOpen={setModalOpen} />
      </div>
      <MapAR hotspots={hotspotList} show={showArMap} />
      <a-scene
        load-map
        class={showArMap ? "hidden" : ""}
        renderer="colorManagement: true"
        background="color: #1a301e"
      >
        {" "}
        {/*1c2f21*/}
        <a-assets>
          <img alt="skybox of a background" id="background" src={Background} />
          <img alt="tree hotspot svg" id="tree" src="/hotspot/tree.svg" />
        </a-assets>
        <a-entity
          id="camera-pivot-container"
          position={posCamera}
          rotation={rotationCamera}
        >
          <a-camera
            camera-limit
            id="camera"
            zoom={zoom}
            position={`0 ${mobileCheck() ? "14" : "25"} 0`}
            orbit-controls="
                  autoRotate: false;
                  distance: 150;
                  enablePan: false;
                  target: #pivot;
                  enableDamping: true;
                  minPolarAngle: 0;
                  maxPolarAngle: 1.5;
                  dampingFactor: 0.25;
                  rotateSpeed:0.14;
                  minDistance:3;
                  enablePan:true;
                  enableZoom:false;
                  panSpeed:20;
                  maxDistance:1000;"
            mouse-cursor
            cursor="rayOrigin: mouse;"
            raycaster="objects: .hotspot"
          ></a-camera>
          <a-entity id="pivot" position="0 4 -6"></a-entity>
        </a-entity>
        <a-entity cursor="rayOrigin:mouse"></a-entity>
        {getHotspots()}
        <Clouds posZ="-28" delayStart="0" posXEnd="250" dur="300000"></Clouds>
        <Clouds posZ="0" delayStart="50000" posXEnd="150" dur="300000"></Clouds>
        <Clouds
          posZ="24"
          delayStart="120000"
          posXEnd="90"
          dur="300000"
        ></Clouds>
        <Clouds
          posZ="50"
          delayStart="150000"
          posXEnd="220"
          dur="700000"
        ></Clouds>
        <a-entity light="type: ambient; color: #fff; intensity: 0.5; antialias: true; sortObjects: true; physicallyCorrectLights: true;"></a-entity>
      </a-scene>

      <div className={`flex justify-center absolute bottom-0 w-screen`}>
        <LocationNavbar
          callback={callback}
          setShowChatNav={setShowChatNav}
          activeAnimationMap={activeAnimationMap}
          setApplyOverlay={setApplyOverlay}
          setModalOpen={setModalOpen}
        />
      </div>
      <div
        className={` flex justify-end fixed pl-4 items-end py-8 top-10 left-0`}
      >
        <div className="flex flex-col space-y-4">
          <ColorsSymbology />
        </div>
      </div>
      <div
        className={` flex justify-end fixed pr-4 items-end py-8 bottom-28 right-0`}
      >
        <div className="flex flex-col space-y-4">
          <ArButton
            toggleArMap={() => setshowArMap(!showArMap)}
            activeAnimationMap={activeAnimationMap}
          />
          <BackButton
            setShowTutorial={setShowTutorial}
            activeAnimationMap={activeAnimationMap}
          />
        </div>
      </div>
      {showHotspotDetail && (
        <HotspotDetail
          setShowHotspotDetail={setShowHotspotDetail}
          name={hotspotDetail?.name}
          location={hotspotDetail?.location}
          logo={hotspotDetail?.imageHotspot}
          description={hotspotDetail?.description}
          media={hotspotMultimedia}
          socials={hotspotDetail?.social}
          howToArrive={hotspotDetail?.howToArrive}
          type={hotspotDetail?.type}
          showHotspotDetail={showHotspotDetail}
        />
      )}
      {showTutorial && <Tutorial setShowTutorial={setShowTutorial} />}
    </div>
  );
};

export default Map;
