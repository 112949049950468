// eslint-disable-next-line no-undef
/* global AFRAME THREE */

if (typeof AFRAME === "undefined") {
  throw new Error(
    "Component attempted to register before AFRAME was available."
  );
}
AFRAME.registerComponent("hover-hotspot", {
  init: function () {
    var self = this;
    this.el.children[1].children[0].setAttribute("opacity", 0);
    this.hover = false;
    this.opacity = 0;
    this.opacityPivot = 0.1;
    this.el.addEventListener("mouseenter", function (evt) {
      self.hover = true;
    });
    this.el.addEventListener("mouseleave", function (evt) {
      self.hover = false;
    });
  },
  tick: function () {
    var mesh = this.el.getObject3D("mesh");
    var text = this.el.children[1].children[0];
    var img = this.el.children[0].getObject3D("mesh");
    if (!mesh || !text) {
      return;
    }

    var scale = mesh.scale.x;
    var scaleUp = scale + 0.1;
    var scaleDown = scale - 0.1;

    text.setAttribute("alpha-test", this.opacity <= 0 ? 30 : 0);
    if (this.hover) {
      if (scale < 1.4) {
        img.scale.set(scaleUp, scaleUp, scaleUp);
        mesh.scale.set(scaleUp, scaleUp, scaleUp);
      }

      if (this.opacity < 1.1) {
        this.opacity += this.opacityPivot;
        text.setAttribute("opacity", this.opacity);
      }
      text.setAttribute("scale", "1.5 1.5 1.5");
    } else {
      if (scale > 1) {
        img.scale.set(scaleDown, scaleDown, scaleDown);
        mesh.scale.set(scaleDown, scaleDown, scaleDown);
      } else {
        text.setAttribute("scale", "0 0 0");
      }

      if (this.opacity > 0) {
        this.opacity -= this.opacityPivot;
        text.setAttribute("opacity", this.opacity);
      } else if (this.opacity <= 0) {
      }
    }
  },
});

console.info("hover-hotspot component loaded to aframe");
