import React, { createRef, useEffect, useState } from "react";
import left from "../../assets/images/icons/left.png";
import right from "../../assets/images/icons/right.png";
//const images = ['https://images.unsplash.com/photo-1506501139174-099022df5260?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80', 'https://images.unsplash.com/photo-1523438097201-512ae7d59c44?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80', 'https://images.unsplash.com/photo-1513026705753-bc3fffca8bf4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80']

const Carousel = ({ media, setShowGallery }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [showCarouselDesktop, setShowCarouselDesktop] = useState(false);

  if (!media.length) media = [];

  const refs = media.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToImage = (i) => {
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const totalImages = media.length;

  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  const sliderControl = (isLeft, i) => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${
        currentImage === i ? "bg-[#02F1A0]" : "bg-[#45474C]"
      } w-3 h-3 rounded-full`}
      style={{ top: "40%" }}
    ></button>
  );

  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| ||a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const checkStarred = (img, i) => {
    if (img.type === "img" || img.type === "360") {
      return (
        <>
          <div className="w-full flex-shrink-0" key={img.src} ref={refs[i]}>
            <img key={i} src={img.src} className="img-size" />
            <div className="img-loading" />
          </div>
        </>
      );
    } else if (img.type === "video") {
      return (
        <div className="w-full flex-shrink-0" key={img.src} ref={refs[i]}>
          <video
            alt={img.name}
            className="block object-cover object-center w-full h-full"
            controls
          >
            <source src={img.src} type="video/mp4"></source>
            <source src={img.src} type="video/ogg"></source>
          </video>
        </div>
      );
    } else if (img.type === "threeD") {
      return (
        <model-viewer
          skybox-image={"skybox-model-viewer.hdr"}
          alt={img.name}
          src={img.src}
          auto-rotate
          shadow-intensity="3"
          className={{ width: "100%", height: "30rem" }}
        >
          <div className="progress-bar hide" slot="progress-bar">
            <div className="update-bar"></div>
          </div>
        </model-viewer>
      );
    }
  };

  const checkStarredDesktop = () => {
    const starred = [];
    media.map((img, i) => {
      if (img.type === "img" || img.type === "360") {
        starred.push(
          <div className="lg:w-[32%!important] ">
            <img
              src={media[i].src}
              className="lg:w-[32%!important] lg:h-[44vh] absolute z-1 img-size"
              key={i}
            />
            <div className="lg:w-[100%!important] lg:h-[44vh] img-loading" />
          </div>
        );
      } else if (img.type === "video") {
        starred.push(
          <div className="lg:w-[32%!important] ">
            <video
              alt={img.name}
              className="block object-cover object-center lg:w-[32%] lg:h-[44vh] absolute z-1"
              key={i}
              controls
            >
              <source src={img.src} type="video/mp4"></source>
              <source src={img.src} type="video/ogg"></source>
            </video>
            <div className="lg:w-[100%!important] lg:h-[44vh] img-loading" />
          </div>
        );
      } else if (img.type === "threeD") {
        starred.push(
          <model-viewer
            skybox-image={"skybox-model-viewer.hdr"}
            alt={img.name}
            src={img.src}
            auto-rotate
            shadow-intensity="3"
            className={{ width: "100%", height: "30rem" }}
          >
            <div className="progress-bar hide" slot="progress-bar">
              <div className="update-bar"></div>
            </div>
          </model-viewer>
        );
      }
    });
    return starred;
  };

  useEffect(() => {
    let isMobile = mobileCheck();
    if (!isMobile) {
      setShowCarouselDesktop(true);
    }
  }, []);

  return (
    <div className="flex justify-center w-screen lg:w-full items-center">
      <div className="relative w-full">
        <div className="carouselComponent md:w-full">
          <div
            className={`${
              showCarouselDesktop
                ? "hidden"
                : "absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2 w-full"
            }`}
          >
            <div className={"flex justify-around w-full items-center"}>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
                onClick={() => previousImage()}
              >
                <img className="text-gray-800 h-6" src={left} alt="Icon News" />
              </button>
              <div className="flex space-x-1">
                {sliderControl(true, 0)}
                {media.length >= 2 && sliderControl(false, 1)}
                {media.length >= 3 && sliderControl(false, 2)}
              </div>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
                onClick={() => nextImage()}
              >
                <img
                  className="text-gray-800 h-6"
                  src={right}
                  alt="Icon News"
                />
              </button>
            </div>
          </div>
          {!showCarouselDesktop ? (
            checkStarred(media[currentImage], currentImage)
          ) : (
            <div
              className="lg:w-full lg:flex lg:flex-row lg:mt-[1rem] lg:justify-evenly"
              onClick={() => setShowGallery(true)}
            >
              {checkStarredDesktop()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
