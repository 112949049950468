import { Entity } from "aframe-react";
import mapImage1 from "../../assets/images/hotspots/type1.png";
import mapImage2 from "../../assets/images/hotspots/type2.png";
import mapImage3 from "../../assets/images/hotspots/type3.png";
import mapImage4 from "../../assets/images/hotspots/type4.png";
import mapImage5 from "../../assets/images/hotspots/type5.png";
import treeIcon from "../../assets/images/icons/tree.png";
import hotelIcon from "../../assets/images/icons/hotel.png";

const Hotspot = ({ hotspot, hotspotInfo }) => {
  const hotspotImgMapping = {
    nature: mapImage2,
    cultural: mapImage3,
    political: mapImage4,
    tourist: mapImage5,
  };
  const hotspotColorMapping = {
    nature: "#228B22",
    cultural: "#90EE90",
    political: "#0000FF",
    tourist: "#BEBEBE",
  };
  return (
    <>
      <Entity
        hover-hotspot
        click-hotspot={`hotspot: ${JSON.stringify(hotspotInfo)}`}
        id={hotspot.id}
        key={hotspot.name}
        class="hotspot"
        geometry={{ primitive: "plane" }}
        material={{
          roughness: 0.5,
          src: hotspotImgMapping[hotspotInfo.hotspot.type] || mapImage5,
          transparent: true,
          alphaTest: 0,
        }}
        shadow={{ cast: true, receive: true }}
        scale={{ x: 2, y: 2, z: 2 }}
        position={{
          x: hotspot.position[0],
          y: hotspot.position[1],
          z: hotspot.position[2],
        }}
        rotation={{ x: -60, y: 0, z: 0 }}
        material-alpha-test
      >
        <Entity
          geometry={{ primitive: "circle" }}
          material={{
            color: hotspotColorMapping[hotspotInfo.hotspot.type],
            transparent: true,
            alphaTest: 0,
          }}
          scale={{ x: 0, y: 0, z: 0 }}
          position={{ x: 0, y: 0.03, z: 0.025 }}
          shadow={{ cast: true, receive: true }}
        />
        <Entity
          position="0 1.2 0"
          id={hotspot.name + "-offset"}
          key={hotspot.name + "-offset"}
        >
          <a-text
            id={hotspot.name + "-text"}
            alpha-test="100"
            key={hotspot.name + "-text"}
            value={hotspot.name}
            font="/Eina01-Bold-msdf.json"
            align="center"
            font-image="/Eina01-Bold.png"
            negate="false"
            color={"#FFFFFF"}
            scale="1.5 1.5 1.5"
            position="0 -.3 0"
          ></a-text>
        </Entity>
      </Entity>
    </>
  );
};

export default Hotspot;
