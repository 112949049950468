import { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import questionIcon from "../../assets/images/icons/questionIcon.png";
import timerIcon from "../../assets/images/icons/timerIcon.png";
import englishIcon from "../../assets/images/icons/englishIcon.png";
import spanishIcon from "../../assets/images/icons/spanishIcon.png";
import englishColorIcon from "../../assets/images/icons/englishColorIcon.png";
import spanishColorIcon from "../../assets/images/icons/spanishColorIcon.png";
import News from "../news/news";
import Faq from "../faq/faq";
import { useTranslation } from "react-i18next";
import { sideVariants } from "../../animation";
import { AnimatePresence, motion, useCycle } from "framer-motion";

const Navbar = ({ setModalOpen, news}) => {
    const { t, i18n } = useTranslation();
    const [navbarOpen, setNavbarOpen] = useCycle(false, true);
    const [languageOpen, setLanguageOpen] = useCycle(false, true);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [languageSelected, setLanguageSelected] = useState(localStorage.getItem("locale") || "es");
    const [showNewsModal, setShowNewsModal] = useState(false);
    const [showFaqModal, setShowFaqModal] = useState(false);
    let [MapName, setMapName] = useState();


    useEffect(() => {
        setModalOpen(showFaqModal || showNewsModal);
    }, [showNewsModal, showFaqModal])

    useEffect(() => {
        setMapName(JSON.parse(localStorage.getItem("mapInfo")).map.name);
    }, []);

    useEffect(() => {
        localStorage.setItem("locale", languageSelected);
        i18n.changeLanguage(languageSelected);
    },[languageSelected])

    const open = (dropdown) => {
        if(dropdown === "language"){
            setLanguageOpen(true);
            if(navbarOpen){
                setNavbarOpen(false);
                return;
            }
            return;
        }else{
            setNavbarOpen(true);
            if(languageOpen){
                setLanguageOpen(false);  
                return;
            }

            return;
        }
    }

    return (
        <>
            <nav className={`border-gray-200 bg-gray-50`} >
                <div className="flex flex-wrap items-center justify-between mx-auto">
                    <div className="w-full py-2 px-4 flex items-center shadow-md">
                        <span className="self-center whitespace-nowrap text-2xl leading-tight font-bold text-[#202945]">{MapName}</span>
                        <button
                            className="ml-auto mr-2 lg:mr-4 cursor-pointer leading-none border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                            type="button"
                            onClick={() => open("language")}
                        >
                            <div className="block pl-3 text-gray-700 rounded lg:border-0 lg:p-0 flex space-x-1 items-center">
                                <img className="text-gray-800 h-8 lg:h-9 lg:ml-[1rem] object-contain" src={languageSelected === "es" ? spanishIcon : englishIcon} alt="Icon Question"/>
                                <span className="hidden lg:block">{languageSelected === "es" ? "Español" : "English"}</span>
                            </div>
                        </button>


                        <button
                            className="cursor-pointer text-2xl lg:text-3xl leading-none border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                            type="button"
                            onClick={() => open("menu")}
                        >
                            {navbarOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
                        </button>
                    </div>
                    <div className={` w-full lg:h-0`}>
                        <AnimatePresence initial={false}>      
                            {languageOpen && ( 
                                <motion.ul className="overflow-hidden flex flex-col mt-2 rounded-lg bg-gray-50 divide-y lg:w-2/6 lg:ml-[75%] lg:rounded-none lg:mt-0"
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={sideVariants}
                                    transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <li className="py-1 px-1">
                                        <div className={`${(languageSelected === "es") ?  'bg-[#02F1A0]' : ''} flex items-center rounded-full p-1 hover:bg-[#02F1A0]`}
                                            onClick={() => {setLanguageSelected("es"); window.location.reload();}}
                                        >
                                            <img className="text-gray-800 mr-2 h-8" src={languageSelected === "es" ? spanishColorIcon : spanishIcon} alt="Icon Question"/>
                                            Español
                                        </div>
                                    </li>
                                    <li className="py-1 px-1">
                                        <div className={`${(languageSelected === "en") ?  'bg-[#02F1A0]' : ''} flex items-center rounded-full p-1`}
                                            onClick={() => {setLanguageSelected("en"); window.location.reload();}}
                                        >
                                            <img className="text-gray-800 mr-2 h-8" src={languageSelected === "es" ? englishIcon : englishColorIcon} alt="Icon Question"/>
                                            English
                                        </div>
                                    </li>
                                </motion.ul>
                            )}
                        </AnimatePresence>  
                    </div>
                    <div className={` w-full lg:h-0`}>
                        <AnimatePresence initial={false}>      
                            {navbarOpen && ( 
                                <motion.ul className="overflow-hidden flex flex-col mt-2 rounded-lg bg-gray-50 divide-y lg:w-2/6 lg:ml-[75%] lg:rounded-none lg:mt-0"
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={sideVariants}
                                    transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                <li className="py-2" onClick={() => setShowNewsModal(true)}>
                                    <button className="block py-2 pl-3 pr-4 text-gray-700 rounded lg:p-0 flex items-center" aria-current="page">
                                        <img className="text-gray-800 mr-4 h-8 lg:ml-[1rem]" src={ timerIcon } alt="Icon News"/> {t("navbar.news")}
                                    </button>
                                </li>
                                <li className="py-2" onClick={() => setShowFaqModal(true)}>
                                    <button className="block py-2 pl-3 pr-4 text-gray-700 rounded lg:border-0 lg:p-0 flex items-center" aria-current="page">
                                        <img className="text-gray-800 mr-4 h-8 lg:ml-[1rem]" src={ questionIcon } alt="Icon Question"/> {t("navbar.frequent-questions")}
                                    </button>
                                </li>
                                </motion.ul>
                            )}
                        </AnimatePresence>  
                    </div>
                </div>
            </nav>
            {showNewsModal && <News news={news} setShowNewsModal={setShowNewsModal} setModalOpen={setModalOpen}/>}
            {showFaqModal && <Faq setShowFaqModal={setShowFaqModal} setModalOpen={setModalOpen}/>}
        </>
      );
  };
  
  export default Navbar;