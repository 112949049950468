/* global AFRAME THREE */
/*
if (typeof AFRAME === 'undefined') {
    throw new Error('Component attempted to register before AFRAME was available.');
}
AFRAME.registerComponent('camera-limit', {
    init: function () 
    {
        this.savePivotPosition = {
            x: this.el.parentEl.children["pivot"].getAttribute("position").x,
            y: this.el.parentEl.children["pivot"].getAttribute("position").y,
            z: this.el.parentEl.children["pivot"].getAttribute("position").z,
        };
    },
    tick: function () {
        this.el.setAttribute("rotation", {
            "x": -60,
            "y": 0,
            "z": 0
        })
        var position = this.el.getAttribute("position");
        
        var x = position["x"];
        var z = position["z"];

        this.el.components['orbit-controls'].data.enabled = true;
        if (!(x < -70 || x > 40 || z < -70 || z > 40)){
            
            this.savePivotPosition = {
                x: this.el.parentEl.children["pivot"].getAttribute("position").x,
                y: this.el.parentEl.children["pivot"].getAttribute("position").y,
                z: this.el.parentEl.children["pivot"].getAttribute("position").z,
            };
            //this.el.parentEl.children["pivot"].setAttribute("position", this.savePivotPosition);
            
            return;
        }
        this.el.components['orbit-controls'].data.enabled = false;
        console.log(this.el.components['orbit-controls'].dolly.position)
        console.log(position)
        

        var newX =  Math.min(Math.max(x, -70),  40);
        var newZ = Math.min(Math.max(z, -70),  40);
        var newPosition = {
            x: newX,
            y : position["y"],
            z: newZ,

        };
        let newPositionPivot = {
            x: newX,
            y : this.el.parentEl.children["pivot"].getAttribute("position").y,
            z: newZ-6,
        };


        this.el.setAttribute("rotation", {
            "x": -60,
            "y": 0,
            "z": 0
        })
        //this.el.components['orbit-controls'].dolly.position.set( newX, position["y"],newZ);
        console.log(this.el.components["orbit-controls"]);
        console.log(this.el.getAttribute("rotation"));
        this.el.parentEl.children["pivot"].setAttribute("position", this.savePivotPosition);
        this.el.setAttribute("position", newPosition);
    },

});

*/

if (typeof AFRAME === "undefined") {
  throw new Error(
    "Component attempted to register before AFRAME was available."
  );
}
AFRAME.registerComponent("camera-limit", {
  tick: function () {
    //this.el.setAttribute("position", "1 1 1")
    var position = this.el.getAttribute("position");
    var x = position["x"];
    var z = position["z"];
    var newX = Math.min(Math.max(x, -25), 20);
    var newZ = Math.min(Math.max(z, -10), 20);
    var newPosition = {
      x: newX,
      y: position["y"],
      z: newZ,
    };
    //this.el.parentEl.children[3].setAttribute("position", newPosition);
    //this.el.setAttribute("position", newPosition);
  },
});
