import backIcon from "../../assets/images/icons/backIcon.png";
import MapModelf from "../../assets/models/Flor.glb";
import ArButton from "../../assets/images/icons/ARIcon.svg";

const model3DModal = ({ name, src, type, setShowModel3DModal }) => {
  return (
    <>
      <div className="lg:hidden">
        <Model3DMobile
          name={name}
          src={src}
          setShowModel3DModal={setShowModel3DModal}
        />
      </div>
      <div className="hidden lg:block">
        <Model3DDesktop
          name={name}
          src={src}
          setShowModel3DModal={setShowModel3DModal}
        />
      </div>
    </>
  );
};

const Model3DMobile = ({ name, src, type, setShowModel3DModal }) => {
  return (
    <>
      <div className="bg-white fixed top-0 left-0 right-0 z-40 w-full lg:inset-0 h-full lg:h-full lg:hidden">
        <nav className="bg-white relative border-gray-200 rounded z-20">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <div className="w-full p-3 flex items-center shadow-md">
              <div
                onClick={() => {
                  setShowModel3DModal(false);
                }}
                className="cursor-pointer text-xl leading-none pr-3 py-1 border border-solid border-transparent block outline-none focus:outline-none"
              >
                <img className="h-6" src={backIcon} alt="Icon News" />
              </div>
              <span className="self-center text-xl font-bold whitespace-nowrap">
                {name}
              </span>
            </div>
          </div>
        </nav>
        <div className="w-full h-full">
          <div className="flex items-center align-center h-full my-auto">
            <model-viewer
              style={{ height: "100vh" }}
              src={src}
              skybox-image={"sky.hdr"}
              ar
              ar-modes="webxr scene-viewer quick-look"
              camera-controls
              poster="poster.webp"
              shadow-intensity="1"
            >
              <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
              </div>
              <button slot="ar-button" id="ar-button">
                <img
                  className={`h-14 w-14 justify-self-center p-2`}
                  src={ArButton}
                  alt={"location"}
                />
              </button>
            </model-viewer>
          </div>
        </div>
      </div>
    </>
  );
};

const Model3DDesktop = ({ name, src, type, setShowModel3DModal }) => {
  return (
    <>
      <div className="bg-white top-0 left-0 right-0 z-40 w-full lg:inset-0 h-full lg:h-full">
        <nav className="bg-white border-gray-200 rounded">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <div className="w-full p-3 flex items-center shadow-md lg:shadow-none lg:justify-center">
              <div
                onClick={() => {
                  setShowModel3DModal(false);
                }}
                className="cursor-pointer text-xl leading-none pr-3 py-1 border border-solid border-transparent block outline-none focus:outline-none lg:hidden"
              >
                <img className="h-6" src={backIcon} alt="Icon News" />
              </div>
              <span className="self-center text-xl font-bold whitespace-nowrap">
                {name}
              </span>
            </div>
          </div>
        </nav>
        <div className="w-full h-full">
          <div className="flex items-center align-center h-full my-auto">
            <model-viewer
              style={{ height: "88vh" }}
              src={src}
              skybox-image={"sky.hdr"}
              ar
              ar-modes="webxr scene-viewer quick-look"
              camera-controls
              poster="poster.webp"
              shadow-intensity="1"
            >
              <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
              </div>
              <button slot="ar-button" id="ar-button">
                <img
                  className={`h-14 w-14 justify-self-center p-2`}
                  src={ArButton}
                  alt={"location"}
                />
              </button>
            </model-viewer>
          </div>
        </div>
      </div>
    </>
  );
};

export default model3DModal;
