import { FaCube } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { buttons } from "../../animation";

const ArButton = ({ toggleArMap, activeAnimationMap }) => {
  return (
    <>
      <AnimatePresence>
        {activeAnimationMap && (
          <div className="hidden">
            <motion.div
              className="flex flex-col cursor-pointer"
              onClick={toggleArMap}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={buttons}
              transition={{ delay: 2, duration: 1 }}
            >
              <div
                className={`bg-[#E5ECFF] flex justify-center rounded-full w-14`}
              >
                <FaCube className="text-[#202945] h-14 w-14 justify-self-center p-2" />
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ArButton;
