import axios from "axios";

export class Api {

    constructor() {
        this.base_url = "https://api-interactive-map.vercel.app";
        
    }

    static get(url) {
        let locale = localStorage.getItem("locale");
        if (locale)
            axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("locale");
        else{
            axios.defaults.headers.common["Accept-Language"] = "es";
        }
        return new Promise((resolve, reject) => {
            // First attempt
            axios.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
        );
    }

    static post(url, data) {
        let locale = localStorage.getItem("locale");
        if (locale)
            axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("locale");
        else{
            axios.defaults.headers.common["Accept-Language"] = "es";
        }
        return new Promise((resolve, reject) => {
            // First attempt
            axios.post(url, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(errors => {
                    console.log(errors);
                });
            }
        );
    }

    async getMap() {
        return await Api.get(`${this.base_url}/map/`);
    }

    async getBot(data) {
        return await Api.post(`${this.base_url}/bot/`, data);
    }

    async getHotspot(idHotpost) {
        return await Api.get(`${this.base_url}/hotspot?hotspot=${idHotpost}`);
    }

}
let api = new Api();


export {
    api
};