import closeIcon from "../../assets/images/icons/closeIcon.png";
import { useTranslation } from "react-i18next";
import { BiChevronUp } from "react-icons/bi";
import { AnimatePresence, motion, useCycle } from "framer-motion";

const Faq = ({ setShowFaqModal, setModalOpen }) => {
  const { t } = useTranslation();
  const [animation, setAnimation] = useCycle(true, false);

  const faqs = [
    {
      question: t("faq.q1"),
      answer: t("faq.a1"),
    },
    {
      question: t("faq.q2"),
      answer: t("faq.a2"),
    },
    {
      question: t("faq.q3"),
      answer: t("faq.a3"),
    },
    {
      question: t("faq.q4"),
      answer: t("faq.a4"),
    },
    {
      question: t("faq.q5"),
      answer: t("faq.a5"),
    },
    {
      question: t("faq.q6"),
      answer: t("faq.a6"),
    },
    {
      question: t("faq.q7"),
      answer: t("faq.a7"),
    },
    {
      question: t("faq.q8"),
      answer: t("faq.a8"),
    },
  ];

  const listQuestions = faqs.map((faq, i) => (
    <div key={i} className="w-full pt-5 p-3">
      <details>
        <summary>
          <h2 className="self-center text-lg italic font-semibold">
            {faq.question}
          </h2>
          <span class="icon">
            <BiChevronUp className="w-6 h-6" />
          </span>
        </summary>
        <p className="text-sm pr-8 pl-4 pt-2">{faq.answer}</p>
      </details>
    </div>
  ));

  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| ||a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const initialResponsive = mobileCheck()
    ? { opacity: 1, scale: 1 }
    : { opacity: 0, scale: 0.75 };

  const onClick = () => {
    setAnimation();
    setTimeout(() => {
      setShowFaqModal(false);
      setModalOpen(false);
    }, 150);
  };

  const isMobile = mobileCheck();

  return (
    <>
      <AnimatePresence>
        {animation && (
          <>
            {!isMobile ? (
              <div className="fixed inset-0 bg-[#202945] opacity-75 z-30"></div>
            ) : null}
            <motion.div
              className="lg:flex lg:justify-center lg:items-center fixed top-0 left-0 right-0 z-30 w-full lg:p-4 lg:inset-0 h-full lg:h-full shadow-lg"
              initial={initialResponsive}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ase: "easeIn",
                  duration: 0.15,
                },
              }}
            >
              <div className="bg-white lg:justify-center relative w-full h-full shadow-lg lg:shadow-none lg:w-1/2 lg:h-[35rem] lg:overflow-y-hidden">
                <nav className="border-gray-200 rounded ">
                  <div className="container flex flex-wrap items-center justify-between mx-auto">
                    <div className="w-full p-3 flex items-center shadow-md">
                      <span className="self-center text-2xl font-bold text-[#202945] whitespace-nowrap">
                        {t("navbar.frequent-questions")}
                      </span>
                      <div
                        onClick={onClick}
                        className="cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                      >
                        <img
                          className="text-gray-800 h-6"
                          src={closeIcon}
                          alt="Icon News"
                        />
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="h-full lg:h-[35rem] scrollbar overflow-y-scroll text-justify padding-bottom">
                  {listQuestions}
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Faq;
