import closeIcon from "../../assets/images/icons/closeIcon.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Carousel from "../carousel/carousel";
import facebook from "../../assets/images/icons/facebook.png";
import instagram from "../../assets/images/icons/instagram.png";
import youtube from "../../assets/images/icons/youtube.png";
import website from "../../assets/images/icons/web.png";
import whatsapp from "../../assets/images/icons/whatsapp.png";
import React, { useState } from 'react';
import Gallery from "../gallery/gallery";
import { useTranslation } from "react-i18next";
import treeIcon from "../../assets/images/icons/tree.png";
import hotelIcon from "../../assets/images/icons/hotel.png";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { AnimatePresence, motion, useCycle } from "framer-motion";

const HotspotDetail = ({name, location, logo, description, setShowHotspotDetail, media, socials, howToArrive, type, showHotspotDetail}) => {
    const { t } = useTranslation();
    const [showGallery, setShowGallery] = useState(false);
    const [animation, setAnimation] = useCycle(true, false);
    var timer;

    useEffect(() => {
        ReactGA.event({
            category : name,
            action: " Click Hotspot",
            label: "Hotspot",
            value: name
        });

    },[])

    const onClick= (event)=> {
        setAnimation();
        clearTimeout(timer);
        if (event.detail === 1 || event.detail === 2) {
          timer = setTimeout(() => {
            setShowHotspotDetail(false);
          },200)}
    }

    const socialMediaMapping = {
        facebook,
        instagram,
        youtube,
        website,
        whatsapp
    }

    const openLink = () => {
        window.open(howToArrive, '_blank');
    }
    
    const checkStarredDesktop = () => {
        //let starred = [];
        let onlyTree = 3;
    
        const starred = [];
        media.forEach((img) => {
          if(img.starred && onlyTree !== 0) {
            starred.push(img);
            onlyTree--;
            }
        })
        if(onlyTree === 3){
            starred.push(media[0]);
        }
        return starred;
      }
    return (
        <>
            <AnimatePresence>
                {animation && (
                    <>
                        <div className="fixed inset-0 bg-[#202945] opacity-75 z-30"></div>
                        <motion.div className="fixed z-40 w-full h-full"
                            initial={{opacity: 0, scale: 0.75}}
                            animate={{opacity: 1, scale: 1,
                                transition: {
                                    ease: "easeOut",
                                    duration: 0.15
                                }
                            }}
                            exit={{opacity: 0, scale: 0.75,
                                transition: {
                                    ease: "easeIn",
                                    duration: 0.15
                                }
                            }}
                        >
                            <div className="relative w-full h-full max-w-2xl lg:h-auto lg:w-1/5">
                                <div className="fixed h-3/4 lg:w-5/6  m-auto px-4 inset-x-0 inset-y-0">
                                    <div className="space-y-6 bg-white rounded lg:rounded-md h-full">
                                        <button type="button" className=" absolute m-2 cursor-pointer z-10 top-[5%] right-[2%] text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 lg:ml-auto inline-flex items-center"
                                        onClick={onClick}
                                        >
                                            <img className="text-gray-800 h-6 w-full" src={ closeIcon } alt="Icon News"/>
                                        </button>
                                        <div className="lg:grid scrollbar overflow-y-auto lg:max-w-full h-full">
                                            <div className="flex flex-col h-full">
                                                <div className="relative">
                                                    <div className="flex justify-center">
                                                        <Carousel media={checkStarredDesktop()} setShowGallery={setShowGallery}/>
                                                    </div>
                                                </div>
                                                <div className="flex justify-start">
                                                    <div className="cursor-pointer text-[#202945] hover:text-gray-900 rounded-lg font-bold italic py-2 mx-6 inline-flex items-center"
                                                        onClick={() => {setShowGallery(true)}}
                                                    >
                                                        {t("hotspot.gallery")} <MdOutlineKeyboardArrowRight className="ml-1 rounded-full bg-[#E5ECFF] text-[#202945]"/>
                                                    </div>
                                                </div>
                                                <div className="flex items-center space-x-4 mx-8 my-2 lg:mx-4">
                                                    {logo ? <img className="lg:hidden w-14 h-14 rounded-full" src={logo} alt=""/> 
                                                    : <img className="lg:hidden w-14 h-14 rounded-full bg-[#E5ECFF] p-2" src={type === "hotel" ? hotelIcon : treeIcon} alt=""/>}
                                                    <h1 className="lg:text-3xl lg:ml-0 font-bold text-[#202945] text-xl">
                                                        <div>{name}</div>
                                                    </h1>
                                                </div>
                                                <div className="flex flex-col lg:flex-row lg:mb-8">
                                                    <div className="lg:flex lg:justify-between lg:mt-4 lg:pb-10 lg:w-1/2" > 
                                                        <div className="mx-8 my-auto overflow-y-auto lg:text-justify">
                                                            <p className="font-medium text-[#202945] text-sm">
                                                                {description}
                                                            </p>
                                                        </div>
                                                    </div>  
                                                    <div className="lg:w-1/2 lg:mb-2 m-auto lg:block lg:justify-end ">
                                                        <div className=" flex justify-center mb-9 ">
                                                            {
                                                                socials && Object.keys(socials).map((s) => {
                                                                    return <img className="hover:cursor-pointer md:w-1/18 md:h-10 md:ml-3 md:mr-3 hidden md:flex mr-2 text-gray-800"
                                                                                src={ socialMediaMapping[s] } alt={s}
                                                                            onClick={() => window.open(socials[s])}/> ;
                                                                })
                                                            }
                                                        </div>
                                                        { howToArrive ?
                                                            <div className="lg:justify-start lg:mx-30 lg:w-1/2 lg:mb-2 m-auto flex lg:justify-center">
                                                                <button type="button" className="mb-4 buttonShadow  w-full flex items-center justify-center bg-[#02F1A0] text-[#202945] text-sm leading-6 font-bold py-2 px-14 rounded-full"
                                                                    onClick={() => openLink()}>
                                                                    {t("hotspot.how-to-arrive")}
                                                                </button>
                                                            </div>
                                                            : <></> }

                                                    </div>   
                                                </div>                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
            {showGallery &&
                <Gallery setShowGallery = {setShowGallery}/>
            }
        </>
    );
  };
  
  export default HotspotDetail;