import { Entity } from "aframe-react";
import clouds1 from "../../assets/images/Clouds/Clouds1.png";
import clouds2 from "../../assets/images/Clouds/Clouds2.png";
import clouds3 from "../../assets/images/Clouds/Clouds3.png";
import { useEffect, useState } from "react";

const cloudsImages = [clouds1, clouds2, clouds3];

const Clouds = ({ posZ, delayStart, posXEnd, dur }) => {
  const [cloud, setCloud] = useState(" ");

  const randomClouds = () => {
    return cloudsImages[Math.floor(Math.random() * (2 - 0 + 1)) + 0];
  };

  useEffect(() => {
    setCloud(randomClouds());
  }, []);

  return (
    <>
      <Entity
        id="Nubes"
        key="Nubes"
        geometry={{ primitive: "plane" }}
        material={`src:${cloud}; transparent: true; depthTest: false; opacity: 0.8`}
        scale={{ x: 30, y: 15, z: 0 }}
        position={{ x: -150, y: 2, z: posZ }}
        animation={`property: position; from: -150 2 ${posZ}; to: ${posXEnd} 2 ${posZ}; dir: normal; dur: ${dur}; easing: linear; loop: true; delay: ${delayStart};`}
        rotation={{ x: -90, y: 0, z: 0 }}
      ></Entity>
    </>
  );
};

export default Clouds;
