/* global pannellum */
import backIcon from "../../assets/images/icons/backIcon.png";
import { useEffect, useRef } from "react";

const ImageModal = ({ name, src, type, setShowImageModal }) => {
  return (
    <>
      <div className="lg:hidden">
        <ImageModalMobile
          name={name}
          src={src}
          type={type}
          setShowImageModal={setShowImageModal}
        />
      </div>
      <div className="hidden lg:block">
        <ImageModalDesktop
          name={name}
          src={src}
          type={type}
          setShowImageModal={setShowImageModal}
        />
      </div>
    </>
  );
};

const ImageModalMobile = ({ name, src, type, setShowImageModal }) => {
  return (
    <>
      <div className="bg-white fixed top-0 left-0 right-0 z-40 w-full lg:inset-0 h-full lg:h-full lg:hidden">
        <nav className="bg-white relative border-gray-200 rounded z-20">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <div className="w-full p-3 flex items-center shadow-md">
              <div
                onClick={() => {
                  setShowImageModal(false);
                }}
                className="cursor-pointer text-xl leading-none pr-3 py-1 border border-solid border-transparent block outline-none focus:outline-none"
              >
                <img className="h-6" src={backIcon} alt="Icon News" />
              </div>
              <span className="self-center text-xl font-bold whitespace-nowrap">
                {name}
              </span>
            </div>
          </div>
        </nav>
        <div className="w-full h-full flex ">
          <div className="flex justify-center overflow-y-scroll">
            <div className="flex items-center my-auto">
              {type === "img" && (
                <img
                  alt={name}
                  className="block object-contain object-center w-full h-auto"
                  src={src}
                />
              )}

              {type === "video" && (
                <video
                  alt={name}
                  className="block object-contain object-center w-full h-auto"
                  controls
                >
                  <source src={src} type="video/mp4"></source>
                  <source src={src} type="video/ogg"></source>
                </video>
              )}
              {type === "360" && (
                <div className="wrapper">
                  <div className="h_iframe h_frame_mobile">
                    <iframe
                      title="360 view"
                      src={"/360view.html?url=" + utf8_to_b64(src)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ImageModalDesktop = ({ name, src, type, setShowImageModal }) => {
  return (
    <>
      <div className="bg-white top-0 left-0 right-0 z-40 w-full lg:inset-0 h-full lg:h-full">
        <nav className="bg-white border-gray-200 rounded">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <div className="w-full p-3 flex items-center shadow-md lg:shadow-none lg:justify-center">
              <div
                onClick={() => {
                  setShowImageModal(false);
                }}
                className="cursor-pointer text-xl leading-none pr-3 py-1 border border-solid border-transparent block outline-none focus:outline-none lg:hidden"
              >
                <img className="h-6" src={backIcon} alt="Icon News" />
              </div>
              <span className="self-center text-xl font-bold whitespace-nowrap">
                {name}
              </span>
            </div>
          </div>
        </nav>
        <div
          className={`${type !== "360" ? "justify-center flex" : ""} w-full `}
        >
          <div className="flex justify-center">
            <div className="flex items-center my-auto lg:object-contain lg:w-full">
              {type === "img" && (
                <img alt={name} className="block gallery-img-size" src={src} />
              )}

              {type === "video" && (
                <video
                  className="block object-contain object-center w-full h-auto"
                  controls
                >
                  <source src={src} type="video/mp4"></source>
                  <source src={src} type="video/ogg"></source>
                </video>
              )}
              {type === "360" && (
                <div className="wrapper">
                  <div className="h_iframe">
                    <iframe
                      title="360 view"
                      src={"/360view.html?url=" + utf8_to_b64(src)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export default ImageModal;
