// eslint-disable-next-line no-undef
/* global AFRAME THREE DRACOLoader */
import MapModel from "../assets/models/Map.glb"

if (typeof AFRAME === 'undefined') {
    throw new Error('Component attempted to register before AFRAME was available.');
}

AFRAME.registerComponent('load-map', {
    init: function () {
        const draco = new THREE.DRACOLoader();
        draco.setDecoderConfig({ type: 'js' });
        draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        const loader = new THREE.GLTFLoader()
        loader.setDRACOLoader(draco)
        loader.load(MapModel, (a) => {
            a.scene.scale.set(140, 100, 120)
            a.scene.position.set(-15.5, -2.5, 3)
            this.el.sceneEl.object3D.add(a.scene);
        });
    }
});

console.info("load-map component loaded to aframe")
