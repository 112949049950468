export const modal = {
    closed: {
      opacity: 0
    },
    open: { opacity: 1 }
};

export const buttons = {
  open: { opacity: 1, width: "auto" },
  collapsed: { opacity: 0, width: 0 }
};

export const sideVariants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 }
};


